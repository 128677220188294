import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = { class: "text-center mb-10" };
var _hoisted_2 = { class: "text-dark mb-3" };
var _hoisted_3 = { class: "text-gray-400 fw-bold fs-4" };
var _hoisted_4 = { class: "text-gray-400 fw-bold fs-4" };
var _hoisted_5 = { class: "fv-row mb-10" };
var _hoisted_6 = { class: "form-label fw-bolder text-gray-900 fs-6" };
var _hoisted_7 = { class: "fv-row mb-10" };
var _hoisted_8 = { class: "form-label fw-bolder text-gray-900 fs-6" };
var _hoisted_9 = { class: "d-flex flex-wrap justify-content-center pb-lg-0" };
var _hoisted_10 = { class: "text-center text-muted text-uppercase fw-bolder my-5" };
var _hoisted_11 = { class: "d-flex flex-wrap justify-content-center pb-lg-0" };
var _hoisted_12 = { class: "text-primary" };
var _hoisted_13 = { class: "text-center text-muted text-uppercase fw-bolder my-5" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_router_link = _resolveComponent("router-link");
    var _component_base_field = _resolveComponent("base-field");
    var _component_base_button = _resolveComponent("base-button");
    var _component_base_social_login = _resolveComponent("base-social-login");
    var _component_Form = _resolveComponent("Form");
    var _component_base_col = _resolveComponent("base-col");
    return (_openBlock(), _createBlock(_component_base_col, {
        class: "bg-white rounded mx-auto",
        style: { "max-width": "375px" }
    }, {
        default: _withCtx(function () { return [
            _createVNode(_component_Form, {
                class: "form w-100 fv-plugins-bootstrap5 fv-plugins-framework",
                onSubmit: _ctx.onSubmitLogin,
                id: "kt_login_password_reset_form",
                "validation-schema": _ctx.formLoginValidate
            }, {
                default: _withCtx(function () { return [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("Log In")), 1),
                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("Enter your email and password")), 1),
                        _createElementVNode("div", _hoisted_4, [
                            _createTextVNode(_toDisplayString(_ctx.$t("Forget Password?")) + " ", 1),
                            _createVNode(_component_router_link, { to: { name: 'password-reset' } }, {
                                default: _withCtx(function () { return [
                                    _createTextVNode(_toDisplayString(_ctx.$t("Reset")), 1)
                                ]; }),
                                _: 1
                            })
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("Email")), 1),
                        _createVNode(_component_base_field, {
                            name: "email",
                            type: "email",
                            placeholder: _ctx.$t('Email')
                        }, null, 8, ["placeholder"])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("Password")), 1),
                        _createVNode(_component_base_field, {
                            name: "password",
                            type: "login_password",
                            placeholder: _ctx.$t('password')
                        }, null, 8, ["placeholder"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_base_button, {
                            showLoading: _ctx.loading.login,
                            class: "w-100"
                        }, {
                            default: _withCtx(function () { return [
                                _createTextVNode(_toDisplayString(_ctx.$t("Login")), 1)
                            ]; }),
                            _: 1
                        }, 8, ["showLoading"])
                    ]),
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("or")), 1),
                    _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("span", {
                            class: "h2 text-uppercase",
                            role: "button",
                            onClick: _cache[0] || (_cache[0] =
                                //@ts-ignore
                                function () {
                                    var args = [];
                                    for (var _i = 0; _i < arguments.length; _i++) {
                                        args[_i] = arguments[_i];
                                    }
                                    return (_ctx.gotoMobileLogin && _ctx.gotoMobileLogin.apply(_ctx, args));
                                })
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.$t("Log in by")) + " ", 1),
                            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("Phone Number")), 1)
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("or")), 1),
                    _createVNode(_component_base_social_login)
                ]; }),
                _: 1
            }, 8, ["onSubmit", "validation-schema"])
        ]; }),
        _: 1
    }));
}
